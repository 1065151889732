import { useMemo } from 'react'
import { dayjs, formatDateForApi } from '@santsg/common'

import { routes } from './routes.def'

export default function useMainMenuItems() {
  const t = useTranslationNs('COMMON')

  return useMemo(
    () => [
      {
        parent: 'price-search',
        items: [
          {
            label: t('dashboard_menu__excursion_price_search', 'Excursion Price Search'),
            route: routes.excursionPriceSearch,
          },
        ],
      },

      {
        parent: 'services',
        items: [
          {
            label: t('dashboard_menu__excursion', 'Excursion'),
            children: [
              {
                label: t('dashboard_menu__excursions_list', 'Excursions List'),
                route: routes.excursions,
              },
              {
                label: t('dashboard_menu__service_ıtems', 'Service Items & Ancillaries'),
                route: routes.serviceItemsAndAncillaries,
              },
              {
                label: t('dashboard_menu__excursion_package', 'Excursion Package'),
                route: routes.excursionPackage,
              },
              {
                label: t('dashboard_menu__info_cocktail', 'Info Cocktail'),
                route: routes.infoCocktail,
                queryParams: {
                  beginDateFrom: formatDateForApi(dayjs().add(1, 'day'), true),
                  beginDateTo: formatDateForApi(dayjs().add(1, 'day'), true),
                },
              },
              {
                label: t('dashboard_menu__guide_commissions', 'Guide Commissions'),
                route: routes.guideCommissions,
              },
              {
                label: t('dashboard_menu__management_monitor', 'Management Monitor'),
                route: routes.excursionManagementMonitor,
              },
            ],
          },
        ],
      },

      {
        parent: 'reports',
        items: [
          {
            label: t('dashboard_menu__excursion_reports', 'Excursion Reports'),
            children: [
              {
                label: t('dashboard_menu__exc_account_control', 'Exc. Account Control'),
                route: routes.excursionAccountControl,
              },
            ],
          },
        ],
      },

      {
        parent: 'master-data',
        items: [
          {
            label: t('dashboard_menu__excursions _definitions', 'Excursions Definitions'),
            children: [
              {
                label: t('dashboard_menu__excursion_variations', 'Variations'),
                route: routes.excursionVariations,
              },
              {
                label: t('dashboard_menu__excursion_facilities', 'Facilities'),
                route: routes.excursionFacilities,
              },
              {
                label: t('dashboard_menu__themes', 'Themes'),
                route: routes.excursionThemes,
              },
              {
                label: t('dashboard_menu__introduction_headers', 'Introduction Headers'),
                route: routes.introductionHeaders,
              },
              {
                label: t('dashboard_menu__guide_types', 'Guide Types'),
                route: routes.guideTypes,
              },
              {
                label: t('dashboard_menu__guides', 'Guides'),
                route: routes.guides,
              },
              {
                label: t('dashboard_menu__assignment', 'Assignment'),
                route: routes.assignment,
              },
              {
                label: t('dashboard_menu__excursion_pickup_times', 'Pick-up Times'),
                route: routes.pickupTimes,
              },
            ],
          },
        ],
      },
    ],
    [t],
  )
}
